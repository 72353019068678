<template>
  <!--
    Sample source: https://codepen.io/havardob/pen/MWKWZxZ
    -->
  <div class="container">
    <!-- code here -->
    <div class="match">
      <!-- <div class="match-header">
        <div class="match-status">Live</div>
        <div class="match-tournament">
          <img src="https://assets.codepen.io/285131/pl-logo.svg" />
          {{ sport }}
        </div>
        <div class="match-actions">
          <button class="btn-icon">
            <i class="material-icons-outlined">grade</i>
          </button>
          <button class="btn-icon">
            <i class="material-icons-outlined">add_alert</i>
          </button>
        </div>
      </div> -->
      <div class="match-content">
        <div class="column">
          <div class="team team--away">
            <div class="team-logo">
              <img :src="awayLogo">
            </div>
            <h2 class="team-name">{{ awayTeam }}<br>
              <button class="match-bet-option">
                {{ awayRecord }}
              </button>
            </h2>
          </div>
        </div>
        <div class="columncenter">
          <div class="match-details">
            <div class="match-date">
              {{ gameDate }}
            </div>
            <div class="match-score">
              <span
                class="match-score-number"
                :class="{ 'match-score-number--leading' : awayScore > homeScore }"
              >
                {{ awayScore }}
              </span>
              <span class="match-score-divider"> - </span>
              <span
                class="match-score-number"
                :class="{ 'match-score-number--leading' : homeScore > awayScore }"
              >
                {{ homeScore }}
              </span>
            </div>
            <div class="match-time-lapsed">
              {{ gameTime }}
            </div>
            <!-- <div class="match-referee">
              Referee: <strong>Joseph Hicks</strong>
            </div> -->
            <!-- <div class="match-bet-options">
              <button class="match-bet-option">3-9</button>
              <button class="match-bet-option">4-8</button>
            </div> -->
            <button class="match-bet-place">
              Buy Tickets
            </button>
          </div>
        </div>
        <div class="column">
          <div class="team team--home">
            <div class="team-logo">
              <img :src="homeLogo">
            </div>
            <h2 class="team-name">{{ homeTeam }}<br>
              <button class="match-bet-option">
                {{ homeRecord }}
              </button>
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {},

  props: {
    sport: {
      type: String,
      default: '',
    },
    homeTeam: {
      type: String,
      default: '',
    },
    homeScore: {
      type: Number,
      default: 0,
    },
    awayTeam: {
      type: String,
      default: '',
    },
    awayScore: {
      type: Number,
      default: 0,
    },
    homeLogo: {
      type: String,
      default: '',
    },
    awayLogo: {
      type: String,
      default: '',
    },
    homeRecord: {
      type: String,
      default: '',
    },
    awayRecord: {
      type: String,
      default: '',
    },
    gameDate: {
      type: String,
      default: '',
    },
    gameTime: {
      type: String,
      default: '',
    },
  },

  setup() {
    return {}
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/_variables';
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap");

*,
*:after,
*:before {
  box-sizing: border-box;
}

.container {
  --color-text-primary: #1c2a38;
  --color-text-secondary: #8a8f98;
  --color-text-alert: #d72641;
  --color-text-icon: #dbdade;
  --color-bg-primary: #fff;
  --color-bg-secondary: #f3f5f9;
  --color-bg-alert: #fdeaec;
  --color-theme-primary: #623ce6;
}

button,
input,
select,
textarea {
  font: inherit;
}

img {
  display: block;
}

strong {
  font-weight: 600;
}

.container {
  font-family: "Inter", sans-serif;
  line-height: 1.5;
  color: var(--color-text-primary);
  background-color: var(--color-bg-secondary);
  padding-bottom: 20px;
  padding-left: 0;
  padding-right: 0;
}

.match {
  background-color: var(--color-bg-primary);
  display: flex;
  flex-direction: column;
  min-width: 300px;
  // border-radius: 10px;
  box-shadow: 0 0 2px 0 rgba(#303030, 0.1), 0 4px 4px 0 rgba(#303030, 0.1);
}

.match-header {
  display: flex;
  border-bottom: 2px solid rgba(#303030, 0.1);
  padding: 16px;
}

.match-status {
  background-color: var(--color-bg-alert);
  color: var(--color-text-alert);
  padding: 8px 12px;
  border-radius: 6px;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  line-height: 1;
  margin-right: auto;
  &:before {
    content: "";
    display: block;
    width: 6px;
    height: 6px;
    background-color: currentColor;
    border-radius: 50%;
    margin-right: 8px;
  }
}

.match-tournament {
  display: flex;
  align-items: center;
  font-weight: 600;
  img {
    width: 20px;
    margin-right: 12px;
  }
}

.match-actions {
  display: flex;
  margin-left: auto;
}

.btn-icon {
  border: 0;
  background-color: transparent;
  color: var(--color-text-icon);
  display: flex;
  align-items: center;
  justify-content: center;
}

.match-content {
  display: flex;
  position: relative;
}

.column {
  padding: 26px 0 16px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% / 3);
}

.columncenter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% / 3);
}

.team {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.team-logo {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--color-bg-primary);
  box-shadow: 0 4px 4px 0 rgba(#303030, 0.15),
    0 0 0 15px var(--color-bg-secondary);
  img {
    width: 35px;
  }
}

.team-name {
  text-align: center;
  margin-top: 24px;
  font-size: 16px;
  font-weight: 600;
}

.match-details {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.match-date,
.match-referee {
  font-size: 14px;
  color: var(--color-text-secondary);
  strong {
    color: var(--color-text-primary);
  }
}

.match-score {
  margin-top: 12px;
  display: flex;
  align-items: center;
}

.match-score-number {
  font-size: 36px;
  font-weight: 600;
  line-height: 1;
  color: var(--color-text-secondary);
  &--leading {
    color: var(--color-theme-primary);
  }
}

.match-score-divider {
  font-size: 28px;
  font-weight: 700;
  line-height: 1;
  color: var(--color-text-icon);
  margin-left: 10px;
  margin-right: 10px;
}

.match-time-lapsed {
  color: #df9443;
  font-size: 14px;
  font-weight: 600;
  margin-top: 8px;
}

.match-referee {
  margin-top: 12px;
}

.match-bet-options {
  display: flex;
  margin-top: 8px;
  padding-bottom: 12px;
}

.match-bet-option {
  margin-left: 4px;
  margin-right: 4px;
  border: 1px solid var(--color-text-icon);
  background-color: #f9f9f9;
  border-radius: 2px;
  color: var(--color-text-secondary);
  font-size: 14px;
  font-weight: 600;
  padding: 4px 8px;
}

.match-bet-place {
  position: absolute;
  bottom: -16px;
  left: 50%;
  transform: translateX(-50%);
  border: 0;
  background-color: var(--color-theme-primary);
  border-radius: 6px;
  padding: 6px 20px;
  color: rgba(#fff, 0.9);
  font-size: 14px;
  box-shadow: 0 4px 8px 0 rgba(#303030, 0.25);
}

// // Codepen spesific styling - only to center the elements in the pen preview and viewport
// .container {
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
//   width: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// }
// End Codepen spesific styling
</style>
