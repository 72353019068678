<template>
  <div
    class="swiper-multiple post-mini-composite"
    style="width: 100%; justify-content: center; min-height: 250px;"
  >
    <div
      class="kf_hd2"
      style="float: none;"
    >
      <span>
        <b-img
          v-b-modal.modal-scrollable
          :src="getTeamImage(sport, team)"
          height="32px"
          style="padding-right: 10px; padding-bottom: 5px;"
        />
        <strong class="titleClass">{{ title }}</strong>
      </span>
    </div>

    <swiper
      :options="swiperOptions"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      style="max-height: 250px;"
    >
      <swiper-slide
        v-for="(post, index) in posts.slice(0, 20)"
        :key="`${uuid}-${index}-Y`"
      >
        <post-mini
          :post="post"
          :tmp="index"
          :minimal="false"
          :collapsed="true"
          style="min-height: 210px;"
        />
      </swiper-slide>

      <swiper-slide v-if="team !== null">
        <div style="background: white; min-height: 210px;">
          <router-link
            class="more-teams-link"
            :to="`/${sport}/${team}`"
          >
            More <span style="color: #fcb737;">{{ team }}</span> Posts
            <br>
            <feather-icon
              icon="ArrowRightCircleIcon"
              size="50"
            />
          </router-link>
        </div>
      </swiper-slide>

      <!-- If we need pagination -->
      <div
        slot="pagination"
        class="swiper-pagination"
        style="position: unset; margin-bottom: 5px;"
      />
    </swiper>
  </div>
</template>

<script>
import {
  ref,
} from '@vue/composition-api'

import {
  BImg,
} from 'bootstrap-vue'

// import style
import 'swiper/swiper-bundle.min.css'

import prosports from '@/config/sports/pro/prosports'
import { v4 as uuidv4 } from 'uuid'

import PostMini from './PostMini.vue'

export default {
  components: {
    PostMini,
    BImg,
  },

  props: {
    team: {
      type: String,
    },
    sport: {
      type: String,
    },
    posts: {
      type: Array,
      default: () => [],
      // validator: (prop) => prop.every(e => typeof e === 'string'),
    },
    title: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: '',
    },
  },

  setup() {
    const uuid = ref(uuidv4())

    const swiperOptions = {
      freeMode: true,
      slidesPerView: 2,
      spaceBetween: 5,
      // Responsive breakpoints
      breakpoints: {
        // when window width is >= 0px
        0: {
          slidesPerView: 2,
          spaceBetween: 5,
        },
        480: {
          slidesPerView: 2,
          spaceBetween: 5,
        },
        640: {
          slidesPerView: 4,
          spaceBetween: 5,
        },
        960: {
          slidesPerView: 5,
          spaceBetween: 5,
        },
        1280: {
          slidesPerView: 6,
          spaceBetween: 5,
        },
        1500: {
          slidesPerView: 7,
          spaceBetween: 5,
        },
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    }

    function getTeamImage(sport, name) {
      if (sport === undefined || (!(sport in prosports) && (sport !== 'sports'))) { // && !(category in soccer))) {
        return ''
      }

      let imagePath = ''
      const tmpname = name.toLowerCase()
      if (sport === 'sports' && tmpname in prosports) {
        imagePath = prosports[tmpname].logo
      } else if (sport in prosports) {
        const { divisions } = prosports[sport]
        Object.keys(divisions).forEach(division => {
          const teamsInDivision = divisions[division].teams
          const x = teamsInDivision.filter(t => t.name.toLowerCase().replace(/\s/g, '') === tmpname.replace(/\s/g, ''))
          if (x.length > 0) {
            imagePath = x[0].logo
          }
        })
      }

      return imagePath
    }

    return {
      uuid,
      swiperOptions,
      getTeamImage,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/_variables';
.post-mini-composite {
  background-color: rgb(233, 233, 233);
  padding: 5px 5px 5px 5px;
}

.more-teams-link {
  width: 100%;
  color: #352a81;
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 800;
  text-align: center;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
</style>
